<template>
  <section class="pantallas__modal">
    <div class="pantallas__top">
      <h2 class="pantallas__top-title">Pantallas On-line</h2>
      <div :class="{
        'pantallas__green-circle': estado == '0',
        'pantallas__red-circle': estado == '1',
      }"></div>
      <font-awesome-icon @click="closeModal" class="pantallas__close-icon" icon="times"></font-awesome-icon>
    </div>
    <div class="pantallas__table-container">
      <table class="pantallas__table">
        <thead class="pantallas__table-head">
          <tr class="pantallas__tr">
            <th class="pantallas__th-name">Nombre</th>
            <th class="pantallas__th">Ubicación</th>
            <th class="pantallas__th">Canal</th>
            <th class="pantallas__th">Última conexión</th>
          </tr>
        </thead>
        <tbody class="pantallas__table-body">
          <tr class="pantallas__tr" v-for="(pantalla, i) in pantallas" :key="i">
            <td class="pantallas__td">{{ pantalla.nombre_pantalla }}</td>
            <td class="pantallas__td">{{ pantalla.ubicacion }}</td>
            <td class="pantallas__td">{{ pantalla.canal[0].nombre_canal }}</td>
            <td class="pantallas__td">{{ pantalla.fecha_conexion | fechaModal }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    estado: {
      type: String,
    },
    pantallas: {
      type: Array
    }
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },

  filters: {
    fechaModal(fecha) {
      if (!fecha) return '';
      return moment(fecha).format('DD/MM/YYYY hh:mm A')
    }
  }
};
</script>

<style>

</style>