<template>

  <div class="card card-shadow card-bars border-round-10">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1 me-3">
          <div class="card-bars-count-num pe-2">{{ pantallas }}</div>
          <div class="card-bars-count-val">
            Pantallas
            <span 
              v-if="opciones.estado == 0"
              class="text-success"
            >
              Online
            </span>
            <span 
              v-if="opciones.estado == 1"
              class="text-danger"
            >
              Offline
            </span>
          </div>
        </div>
        <div class="flex-shrink-0">
          <img
          v-show="opciones.estado == '0'"
            class="img-obj-60"
            src="@/assets/img/tv/online_vermas.svg"
          />
          <img
          v-show="opciones.estado == '1'"
            class="img-obj-60"
            src="@/assets/img/tv/offline_vermas.svg"
          />
        </div>
      </div>
      <button 
        @click="openModal(opciones.estado)" 
        class="btn btn-custom-color-white border">
          Ver más
      </button>
      <span 
        v-if="opciones.estado == 0"
        class="position-absolute top-0 start-100 translate-middle p-2 bg-success border border-light rounded-circle"
        >
      </span>
      <span 
        v-if="opciones.estado == 1"
        class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle"
        >
      </span>
    </div>
  </div>

<!-- 
  <section class="tv-section__data-container">
    <div class="tv-status__container">
      <div class="tv-status__left">
        <p class="tv-status__number">{{ pantallas }}</p>
        <div class="d-flex align-items-center">
          <p class="tv-status__title">
            {{ opciones.title}}
            <button
              disabled
              class="ms-1 mb-0"
              :class="{
                'tv-status__green-circle': opciones.estado == 0,
                'tv-status__red-circle': opciones.estado == 1,
              }"
            ></button>
          </p>
        </div>
      </div>
      <div class="tv-status__right">
        <img
          v-show="opciones.estado == '0'"
          class="tv-status__icon"
          src="@/assets/img/tv/online_vermas.svg"
          alt=""
        />
        <img
          v-show="opciones.estado == '1'"
          class="tv-status__icon"
          src="@/assets/img/tv/offline_vermas.svg"
          alt=""
        />
        <button @click="openModal(opciones.estado)" class="tv-status__button">
          Ver más
        </button>
      </div>
    </div>
  </section> -->

</template>

<script>
export default {
  props: {
    pantallas: {
      type: String,
    },
    opciones: {
      type: Object
    }
  },
  methods: {
    openModal(id_) {
      this.$emit("openModal", id_);
    },
  },
};
</script>

<style></style>
