<template>

    <div class="card card-numbers border-round-10 card-shadow mb-3">
      <div class="card-body">
        <div class="card-numbers-head d-flex align-items-center color-main">
          <img class="card-numbers-head-icon" src="@/assets/img/tv/Icono_canal_corporativo.png"/>
          <div class="card-numbers-head-num">{{ pantallas }}</div>
          <div class="card-numbers-head-text">
            Pantallas <br>
            Contratadas
          </div>
        </div>
        <div class="card-numbers-text mt-3">
          {{ intro_text }}
        </div>
      </div>
    </div>

<!--   <section class="tv-plan__section-container">
    <div class="tv-plan__section-top">
      <img class="tv-plan__top-img" src="@/assets/img/tv/Icono_canal_corporativo.png" alt="" />
      <h4 class="tv-plan__top-number">{{pantallas}}</h4>
      <div class="tv-plan__top-right">
        <h4 class="tv-plan__top-text">Pantallas</h4>
        <h4 class="tv-plan__top-text">Contratadas</h4>
      </div>
    </div>
    <p class="tv-plan__text">
      Mural Digital es una excelente herramienta para transmitir
      acontecimientos, videos e información importante de manera segmentada, a
      través de tus pantallas corporativas. Te recomendamos ubicarlas en lugares
      estratégicos de tu negocio y mantenerlas actualizadas para potenciar su
      efectividad.
    </p>
  </section> -->
</template>

<script>
export default {
  props: {
      pantallas: {
        type: String,
      },
  },
  data() {
    return {

      //FF
      intro_text: "Mural Digital es una excelente herramienta para transmitir acontecimientos, videos e información importante de manera segmentada, a través de tus pantallas corporativas. Te recomendamos ubicarlas en lugares estratégicos de tu negocio y mantenerlas actualizadas para potenciar su efectividad."
    }
  }
};
</script>

<style>
</style>