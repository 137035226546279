<template >

  <section id="muro-digital">
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <i class="fa-solid fa-circle-play color-secondary"></i>
          Crea, edita y administra tu Mural Digital estés donde estés.
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          :to="{ name: 'CanalCorporativo-add-canales' }"
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear canal
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h3 class="font-title-bold color-main">
                Total pantallas
            </h3>
            <PantallasPlan 
              :pantallas="CantidadPantallasAdmin.pantallas_contrato" 
            />
          </div>
          <div class="col-12 col-lg-6">
            <h3 class="font-title-bold color-main">
                Estado pantallas
            </h3>
            <div class="row">
              <div  class="col-12 col-xl-6 mb-3">
                <PantallasRegistro 
                  :pantallas="CantidadPantallasAdmin.registradas"    
                  :opciones="opciones_pantallas_registradas"
                />
              </div>
              <div  class="col-12 col-xl-6 mb-3">
                <PantallasRegistro 
                  :pantallas="CantidadPantallasAdmin.no_registradas" 
                  :opciones="opciones_pantallas_no_registradas"
                />
              </div>
              <div  class="col-12 col-xl-6 mb-3">
                <PantallasOnline 
                  :pantallas="CantidadPantallasAdmin.online"
                  :opciones="opciones_pantallas_online"
                  @openModal="openModal('0')"
                />
              </div>
              <div  class="col-12 col-xl-6 mb-3">
                <PantallasOnline 
                  :pantallas="CantidadPantallasAdmin.offline"
                  :opciones="opciones_pantallas_offline"
                  @openModal="openModal('1')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tv-dash__modal" v-show="open_modal == true">
        <PantallasOnlineModal
          :pantallas="PantallasPaginadas"
          @closeModal="closeModal"
        />
    </div>

<!--     <section class="tv-section">
      <h2 class="organigrama-section__title">
        Crea, edita y administra tu Mural Digital estés donde estés.
      </h2>
      <div class="tv-dash__wrapper">
        <div class="tv-dash__left">
          <div class="mb-4">
            <h3 class="tv-dash__left-title mb-0">Total pantallas</h3>
          </div>
          <PantallasPlan :pantallas="CantidadPantallasAdmin.pantallas_contrato" />
        </div>
        <div class="tv-dash__right">
          <div class="tv-dash__right-top mb-4">
            <h3 class="tv-dash__right-title mb-0">Estado pantallas</h3>
            <router-link
              :to="{ name: 'CanalCorporativo-add-canales' }"
              class="tv-dash__right-button"
            >+ Crear canal
              </router-link>
          </div>
          <div class="tv-dash__right-bottom">
            <PantallasRegistro 
              :pantallas="CantidadPantallasAdmin.registradas"    
              :opciones="opciones_pantallas_registradas"
            />
            <PantallasRegistro 
              :pantallas="CantidadPantallasAdmin.no_registradas" 
              :opciones="opciones_pantallas_no_registradas"
            />
            <PantallasOnline
              :pantallas="CantidadPantallasAdmin.online"
              :opciones="opciones_pantallas_online"
              @openModal="openModal('0')"
            />
            <PantallasOnline
              :pantallas="CantidadPantallasAdmin.offline"
              :opciones="opciones_pantallas_offline"
              @openModal="openModal('1')"
            />
          </div>
        </div>
      </div>
      <div class="tv-dash__modal" v-show="open_modal == true">
        <PantallasOnlineModal
          :pantallas="PantallasPaginadas"
          @closeModal="closeModal"
        />
      </div>
    </section> -->
    
  </section>

</template>

<script>
import PantallasRegistro from "./PantallasRegistro.vue";
import PantallasPlan from "./PantallasPlan.vue";
import PantallasOnline from "./PantallasOnline.vue";
// import { mapState } from "vuex";
import PantallasOnlineModal from "./PantallasOnlineModal.vue";
import { GET_SCREENS_REGISTERED } from "@/apollo/queries";
import { GET_PANTALLAS_PAGINADAS } from "@/apollo/queries";
// import { GET_TOTAL_PANTALLAS } from "@/apollo/queries";
import { GET_TOTAL_PANTALLAS_ONOFF } from "../../apollo/queries";
export default {
  components: {
    PantallasRegistro,
    PantallasPlan,
    PantallasOnline,
    PantallasOnlineModal,
  },
  data() {
    return {
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      CantidadPantallasAdmin: {},
      PantallasPaginadas: [],
      // TotalPantallas: [],
      TotalPantallasOn: [],
      TotalPantallasOff: [],
      open_modal: false,
      opciones_pantallas_registradas: {
        title: "Pantallas registradas",
        estado: "0",
      },
      opciones_pantallas_no_registradas: {
        title: "Pantallas sin registrar",
        estado: "1",
      },
      opciones_pantallas_online: {
        title: "Pantallas Online",
        estado: "0",
      },
      opciones_pantallas_offline: {
        title: "Pantallas Offline",
        estado: "1",
      },
    }
  },
  
  computed: {
    // ...mapState("canalCorporativoModule", ["CantidadPantallasAdmin"]),
  },

  methods: {
    openModal(estado) {
      let ids_pantallas = [];
      if (estado == '0') {
        ids_pantallas = this.TotalPantallasOn.reduce((prev, curr) => prev.concat(curr.ids), []);
      } else if (estado == '1') {
        ids_pantallas = this.TotalPantallasOff.reduce((prev, curr) => prev.concat(curr.ids), []);
      } else {
        console.error("error..")
      }

      this.getPantallasPaginadas(ids_pantallas);

      this.open_modal = true;
    },
    closeModal() {
      this.PantallasPaginadas = [],
      this.open_modal = false;
    },


    getCantidadPantallasAdmin() {
      this.$apollo.query({
        query: GET_SCREENS_REGISTERED,
        variables: {
          id_empresa: this.id_empresa
        }
      }).then(res => {
        this.CantidadPantallasAdmin = res.data.CantidadPantallasAdmin;
      });
    },

    // getTotalPantallas() {
    //   this.$apollo.query({
    //     query: GET_TOTAL_PANTALLAS,
    //     variables: {
    //       idEmpresa: this.id_empresa,
    //       limite: "20"
    //     }
    //   }).then(res => {
    //     this.TotalPantallas = res.data.TotalPantallas;
    //     // console.log("TotalPantallas:", JSON.stringify(this.TotalPantallas));
    //   }); 
    // },
    
    getPantallasPaginadas(ids_pantallas = ["2", "3", "4", "5", "6"]) {
      this.$apollo.query({
        query: GET_PANTALLAS_PAGINADAS,
        variables: {
          idEmpresa: this.id_empresa,
          idPantalla: ids_pantallas
        }
      }).then(res => {
        this.PantallasPaginadas = res.data.PantallasPaginadas;
      }).catch(err => console.log("Error:", err));
    },

    getTotalPantallasOnOff() {
      // TODO: combinar queries con apollo compose
      this.$apollo.query({
        query: GET_TOTAL_PANTALLAS_ONOFF,
        variables: {
          idEmpresa: this.id_empresa,
          limite: "20",
          onlineOffline: "1"
        }
      }).then(res => {
        this.TotalPantallasOn = res.data.TotalPantallasOnOff;
      }).catch(err => console.log(err));
    

      this.$apollo.query({
        query: GET_TOTAL_PANTALLAS_ONOFF,
        variables: {
          idEmpresa: this.id_empresa,
          limite: "20",
          onlineOffline: "2"
        }
      }).then(res => {
        this.TotalPantallasOff = res.data.TotalPantallasOnOff;
      }).catch(err => console.log(err));
    },

  },

  mounted() {
    this.getCantidadPantallasAdmin();
    this.getTotalPantallasOnOff();
    // this.getTotalPantallas();
  }

};
</script>

<style>
</style>